import {createSlice} from '@reduxjs/toolkit'
import {executeCalc} from "../core/calc";

export const initialState = {
    current: [],
    prev: [],
    op: '',
};


export const counterSlice = createSlice({
    name: 'calc',
    initialState,
    reducers: {
        addDigit: (state, action) => {
            console.log(action)
            state.current.push(action.payload)
        },
        setOp: (state, action) => {
            console.log(action)
            const prev = state.prev
            const op = action.payload
            const current = state.current
            console.log(prev + '', op+ '', current+ '')
            state.prev = executeCalc(prev, state.op, current)
            state.op = op
            state.current = []
        },
        setEquals: (state) => {

            state.prev = executeCalc(state.prev, state.op, state.current)
            state.op = ''
            state.current = []
        },
        clear: (state) => {
            state.prev = initialState.prev
            state.op = initialState.op
            state.current = initialState.current
        },
        backspace: (state) => {
            const val = state.current.pop()

            const even = state.current.length % 2 === 1
            if(even){
                state.current.pop()
                state.current.push(val)
            }
        },
    },
})

export const {addDigit, setOp, setEquals,clear,backspace} = counterSlice.actions

export default counterSlice.reducer
