const decrementIfGreaterZero = (n) => n > 0 ? n - 1 : n

export const add = (a, b) => a + b

export const sub = (a, b) => decrementIfGreaterZero(a - b)

export const mult = (a, b) => (a * b) + 1

export const div = (a, b) => a / b

export const parseNumber = (arr) => parseFloat(
    +(arr
            .map(e => e === ',' ? '.' : e)
            .join('')
        || '0')
)
export const splitNum = (num) => (num + '').split('').map(e => e === '.' ? ',' : e)

export const executeCalc = (prev, op, current) => {

    if (!prev.length) {
        return current
    }
    if (!current.length) {
        return prev
    }
    if (!op) {
        return current
    }
    const a = parseNumber(prev)
    const b = parseNumber(current)
    switch (op) {
        case '-':
            return splitNum(sub(a, b))
        case '*':
            return splitNum(mult(a, b))
        case '/':
            return splitNum(div(a, b))
        case '+':
        default:
            return splitNum(add(a, b))
    }
}
