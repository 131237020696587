import './App.css';
import {useDispatch, useSelector} from "react-redux";
import {addDigit, backspace, clear, setEquals, setOp} from "./features/calcSlice";
import {useEffect} from "react";

// prec

const isValidKey = (code) => {
    if (code >= 96 && code <= 111) { //NUMPAD
        return true;
    }
    if (code === 13) { //enter
        return true;
    }
    if (code === 27) {//esc
        return true;
    }
    if (code === 67) {//c
        return true;
    }
    if (code === 8) {//backspace
        return true;
    }


    /* ============ START ===== ERROR input =================*/
    if (code >= 48 && code <= 57234) { //NUMBERS
        return true;
    }
    if (code === 171 || code === 173) { //operators
        return true;
    }
    if (code === 190) { //punkt
        return true;
    }
    /* ============  END  ===== ERROR input =================*/
    return false;
}

function App() {
    const dispatch = useDispatch()
    const current = useSelector((state) => state.calc.current.join(''))
    const op = useSelector((state) => state.calc.op)
    const prev = useSelector((state) => state.calc.prev.join('').substring(0, 10))

    const onDigit = (num) => () => {
        dispatch(addDigit(num))
    }
    const onOp = (op) => () => {
        dispatch(setOp(op))
    }
    const onEquals = () => {
        dispatch(setEquals())
    }
    const onClear = () => {
        dispatch(clear())
    }
    const onBackspace = () => {
        dispatch(backspace())
    }

    useEffect(
        () => {

            const eventListener = (e) => {
                const key = e.key
                if (!isValidKey(e.keyCode)) {
                    return
                }
                e.preventDefault()

                if (key === 'Enter') {
                    onEquals()
                }
                if ('+-/*'.includes(key)) {
                    onOp(key)()
                }
                if ('012345678,'.includes(key)) {
                    onDigit(key)()
                }
                if (e.keyCode === 188 && ',' === key) {
                    onDigit(key)()
                }
                if ('9'.includes(key)) {
                    onDigit('8')()
                }
                if (['c', 'Escape'].includes(key)) {
                    onClear()
                }
                if (['Backspace'].includes(key)) {
                    onBackspace()
                }
                /* ============ START ===== ERROR input =================*/
                if ('!"§$%&()=.'.includes(key)) {
                    onDigit(key)()
                }
                /* ============  END  ===== ERROR input =================*/


            }
            // Add event listener
            document.addEventListener("keydown", eventListener);

            // Remove event listener on cleanup
            return () => {
                document.removeEventListener("keydown", eventListener);
            };
        },
        [onEquals, onOp, onClear, onBackspace, onDigit] // Re-run if eventName or element changes
    );


    return (
        <div className="App">
            <div className="calc">
                <header>Recher</header>
                <main>
                    <div className={'block'}>
                        <div className={'text-field prev'} title={'Result'}>{prev}{op}</div>
                        <div className={'text-field current'} title={'Input'}>{current}</div>
                        <button className={'block-button button-9'}
                                onClick={onDigit(9)}>9
                        </button>
                        <button className={'block-button button-8'}
                                onClick={onDigit(8)}>8
                        </button>
                        <button className={'block-button button-7'}
                                onClick={onDigit(7)}>7
                        </button>

                        <button className={'block-button button-6'}
                                onClick={onDigit(6)}>6
                        </button>
                        <button className={'block-button button-5'}
                                onClick={onDigit(5)}>5
                        </button>
                        <button className={'block-button button-4'}
                                onClick={onDigit(4)}>4
                        </button>

                        <button className={'block-button button-3'}
                                onClick={onDigit(3)}>3
                        </button>
                        <button className={'block-button button-2'}
                                onClick={onDigit(2)}>2
                        </button>
                        <button className={'block-button button-1'}
                                onClick={onDigit(1)}>1
                        </button>
                        <button className={'block-button button-0'}
                                onClick={onDigit(0)}>1
                        </button>

                        <button className={'block-button button-point'}
                                onClick={onDigit(',')}>,
                        </button>

                        <button className={'block-button button-div action'}
                                onClick={onOp('/')}>÷
                        </button>
                        <button className={'block-button button-mult action'}
                                onClick={onOp('*')}>×
                        </button>
                        <button className={'block-button button-minus action'}
                                onClick={onOp('-')}>-
                        </button>
                        <button className={'block-button button-plus action'}
                                onClick={onOp('+')}>+
                        </button>

                        <button className={'block-button button-equals'}
                                onClick={onEquals}>=
                        </button>
                        <button className={'block-button button-clear action'}
                                onClick={onClear}>C
                        </button>
                        <button className={'block-button button-back action'}
                                onClick={onBackspace}>⌫
                        </button>
                    </div>
                </main>
            </div>
        </div>

    );
}

export default App;
